/*------------------------*/

input:focus,
button:focus,
.form-control:focus {
  outline: none;
  box-shadow: none;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #fff;
}

/*----------step-wizard------------*/
.d-flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

/*---------signup-step-------------*/
.bg-color {
  background-color: #333;
}

.signup-step-container {
  /* padding: 150px 0px; */
  padding-bottom: 60px;
}

.wizard .nav-tabs {
  position: relative;
  margin-bottom: 0;
  border-bottom-color: transparent;
}

.wizard > div.wizard-inner {
  position: relative;
  margin-bottom: 100px;
  text-align: center;
}

.connecting-line {
  height: 3px;
  background: var(--primary-bg-color-3);
  position: absolute;
  width: 72%;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 21px;
  z-index: 1;
}

.wizard .nav-tabs > li.active > a,
.wizard .nav-tabs > li.active > a:hover,
.wizard .nav-tabs > li.active > a:focus {
  color: #555555;
  cursor: default;
  border: 0;
  border-bottom-color: transparent;
}

span.round-tab {
  width: 30px;
  height: 30px;
  line-height: 30px;
  display: inline-block;
  border-radius: 50%;
  background: var(--primary-bg-color-3);
  z-index: 2;
  position: absolute;
  left: 0;
  text-align: center;
  font-size: 16px;
  color: #0e214b;
  /* font-weight: 500; */
  /* border: 1px solid #ddd; */
}

span.round-tab i {
  color: #555555;
}

.wizard li.active span.round-tab {
  color: var(--primary-color);
  background: #ff8d00;
  /* border-color: var(--primary-color); */
}

.wizard li.active span.round-tab i {
  color: #5bc0de;
}

.wizard .nav-tabs > li.active > a i {
  color: #ff8d00;
}

.wizard .nav-tabs > li {
  width: 24%;
}

.wizard li:after {
  content: " ";
  position: absolute;
  left: 46%;
  opacity: 0;
  margin: 0 auto;
  bottom: 0px;
  border: 5px solid transparent;
  border-bottom-color: red;
  transition: 0.1s ease-in-out;
}

.wizard .nav-tabs > li a {
  width: 30px;
  height: 30px;
  margin: 20px auto;
  border-radius: 100%;
  padding: 0;
  background-color: transparent;
  position: relative;
  top: 0;
}

.wizard .nav-tabs > li a i {
  position: absolute;
  top: 50px;
  /* font-style: normal; */
  /* font-weight: 400; */
  white-space: nowrap;
  left: 50%;
  transform: translate(-33%, -70%);
  font-size: 14px;
  /* font-weight: 700; */
  color: var(--primary-bg-color-3);
}

.wizard .nav-tabs > li a:hover {
  background: transparent;
}

.wizard .tab-pane {
  position: relative;
  padding-top: 20px;
}

.wizard h3 {
  margin-top: 0;
}

.prev-step,
.next-step {
  font-size: 13px;
  padding: 8px 24px;
  border: 1px solid rgba(245, 245, 245, 0.2);
  border-radius: 20px;
  margin-top: 30px;
}

.next-step {
  background-color: rgba(245, 245, 245, 0.2);
  color: white;
}

.skip-btn {
  background-color: #cec12d;
}

.step-head {
  font-size: 20px;
  text-align: center;
  /* font-weight: 500; */
  margin-bottom: 20px;
}

.term-check {
  font-size: 14px;
  /* font-weight: 400; */
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 40px;
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 40px;
  margin: 0;
  opacity: 0;
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: 40px;
  padding: 0.375rem 0.75rem;
  /* font-weight: 400; */
  line-height: 2;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: 38px;
  padding: 0.375rem 0.75rem;
  line-height: 2;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.footer-link {
  margin-top: 30px;
}

.all-info-container {
}

.list-content {
  margin-bottom: 10px;
}

.list-content a {
  padding: 10px 15px;
  width: 100%;
  display: inline-block;
  background-color: #f5f5f5;
  position: relative;
  color: #565656;
  /* font-weight: 400; */
  border-radius: 4px;
}

.list-content a[aria-expanded="true"] i {
  transform: rotate(180deg);
}

.list-content a i {
  text-align: right;
  position: absolute;
  top: 15px;
  right: 10px;
  transition: 0.5s;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color: #fdfdfd;
}

.list-box {
  padding: 10px;
}

.signup-logo-header .logo_area {
  width: 200px;
}

.signup-logo-header .nav > li {
  padding: 0;
}

.signup-logo-header .header-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.list-inline li {
  display: inline-block;
}

.pull-right {
  float: right;
}

/*-----------custom-checkbox-----------*/
/*----------Custom-Checkbox---------*/
input[type="checkbox"] {
  position: relative;
  display: inline-block;
  margin-right: 5px;
}

input[type="checkbox"]::before,
input[type="checkbox"]::after {
  position: absolute;
  content: "";
  display: inline-block;
}

/* input[type="checkbox"]::before{
    height: 16px;
    width: 16px;
    border: 1px solid #999;
    left: 0px;
    top: 0px;
    background-color: #fff;
    border-radius: 2px;
} */
/* input[type="checkbox"]::after{
    height: 5px;
    width: 9px;
    left: 4px;
    top: 4px;
} */
/* input[type="checkbox"]:checked::after{
    content: "";
    border-left: 1px solid #fff;
    border-bottom: 1px solid #fff;
    transform: rotate(-45deg);
}
input[type="checkbox"]:checked::before{
    background-color: #18ba60;
    border-color: #18ba60;
} */

@media (max-width: 767px) {
  .sign-content h3 {
    font-size: 40px;
  }

  .wizard .nav-tabs > li a i {
    display: none;
  }

  .signup-logo-header .navbar-toggle {
    margin: 0;
    margin-top: 8px;
  }

  .signup-logo-header .logo_area {
    margin-top: 0;
  }

  .signup-logo-header .header-flex {
    display: block;
  }
}

.stepcard {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  border: 1px solid rgba(173, 173, 173, 0.2);
  border-radius: 5px;
  font-family: "Russo One", sans-serif;
  color: #c9c8c5;
  background: #0c1d31;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #c9c8c5;
  background-color: transparent;
  background-clip: padding-box;
  border: 1px solid rgba(245, 245, 245, 0.2);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 5px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-family: "Russo One", sans-serif;
}

.form-control:focus {
  color: #c9c8c5;
  background-color: transparent;
  border-color: rgb(241 70 104 / 25%);
  outline: 0;
}

.card label {
  color: #f0eee9;
  /* font-size: 13px; */
  margin-bottom: 0.5rem !important;
  font-family: 'Poppins';
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
  font-family: 'Poppins';
}

small {
  font-size: 10px;
}

.brief {
  padding-bottom: 85px !important;
  border: 1px solid #fff;
  width: 100%;
  background: transparent;
  color: #fff;
  padding: 15px 15px;
}

.step-input-value {
  color: var(--primary-color);
}

.card-span {
  font-size: 13px;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #f0eee9;
  background-color: transparent;
  background-image: url(../../images/down.png);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select option {
  margin: 40px;
  background: #1f4a7d;
  color: #f0eee9;

  /* text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4); */
}

select option:hover {
  color: whitesmoke !important;
  background: #bf5279 !important;
}

.react-datetime-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border: 1px solid white;
  padding: 7px;
  border-radius: 5px;
}

svg.react-datetime-picker__clear-button__icon.react-datetime-picker__button__icon {
  stroke: #f0eee9;
}

svg.react-datetime-picker__calendar-button__icon.react-datetime-picker__button__icon {
  stroke: #f0eee9;
}

.react-datetime-picker__inputGroup__input {
  min-width: 0.54em;
  height: calc(100% - 2px);
  position: relative;
  padding: 1px;
  border: 0;
  background: none;
  font: inherit;
  box-sizing: content-box;
  color: #f0eee9;
  -moz-appearance: textfield;
}

.react-datetime-picker__inputGroup__input::placeholder {
  color: #f0eee9;
}

.react-datepicker__input-container input[type="text"] {
  width: 100%;
  background: transparent;
  color: #fff;
  border: 1px solid #fff;
  height: 40px;
  padding-left: 10px;
}

.switch {
  display: inline-block;
  height: 34px;
  position: relative;
  width: 60px;
}

.switch input {
  display: none;
}

.slider {
  background-color: #ccc;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;
}

.slider:before {
  background-color: #fff;
  bottom: 4px;
  content: "";
  height: 26px;
  left: 4px;
  position: absolute;
  transition: 0.4s;
  width: 26px;
}

input:checked + .slider {
  background-color: #66bb6a;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.wrapper {
  padding: 10px;
}

.image--cover {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin: 20px;

  object-fit: cover;
  object-position: center right;
}

.react-datepicker__close-icon::after {
  background-color: #a52e21 !important;
}
